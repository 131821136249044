<template>
  <div class="container">
    <div class="user-info">
      <div class="avatar">
        <van-image
          round
          width="5rem"
          height="5rem"
          :src="headImg"
        />
      </div>
      <div class="extinfo">
        <div class="account">{{mobile}}</div>
        <div class="vips">
          <!--<span class="item classic" v-show="yVip">经典会员</span>-->
          <span class="item diamond" v-show="vip">全站会员</span>
        </div>
      </div>
    </div>
    <div class="service">
      <div class="title">我的服务</div>
      <div class="list">
        <van-grid :column-num="4" icon-size="28px">
          <van-grid-item icon-prefix="iconfont icon" icon="shouhuodizhi" icon-color="#1296db" text="收货地址" to="address" />
          <van-grid-item icon-prefix="iconfont icon" icon="kaquan" icon-color="#f08080" text="优惠券" to="coupon" />
          <van-grid-item icon-prefix="iconfont icon" icon="dingdan" icon-color="#daa520" text="全部订单" to="orderList" />
          <van-grid-item icon-prefix="iconfont icon" icon="xiugaimima" icon-color="#d8bfd8" text="密码管理" to="password" />
          <!-- <van-grid-item icon-prefix="iconfont icon" icon="zaixiankefu" icon-color="#bc8f8f" text="联系客服" /> -->
        </van-grid>
      </div>
    </div>
    <div class="logout">
      <van-button type="default" block @click='logout'>退出登录</van-button>
      <!--<router-link to="./login" > <van-image :src="require('@/assets/icons/logo.png')"></van-image> </router-link>-->
    </div>
    <nav-bar></nav-bar>
  </div>
</template>

<script>
  import { useRouter } from "vue-router"
  import { removeLocal} from "@/utils/util"
  import navBar from "@/components/NavBar";
  import { userInfo} from "@/service/user"

  export default {
    name: "User",
    components: {
      navBar,
    },
    data(){
      return{
        mobile:'',
        vip:false,
        yVip:false,
        headImg:''
      }
    },
    mounted:function(){
    const storage= localStorage.getItem("token-hyxt");
    const token=JSON.parse(storage);
    userInfo(token.userId).then(res =>{
      this.mobile=res.data.data.mobile;
      if(res.data.data.avatar){
       this.headImg=res.data.data.avatar;
      }else{
        this.headImg= "https://img.yzcdn.cn/vant/cat.jpeg";
      }

      if(1==res.data.data.vip){
        this.vip=true;
      }
      if(1==res.data.data.yVip){
        this.yVip=true;
      }
    });



    },
    methods:{
      userInfo:function () {
        userInfo();
      }
    },
    setup() {
      const router = useRouter();
      // 退出登录
      const logout = () => {
        removeLocal('token-hyxt');
        router.replace('/login');
        setTimeout(function () {
          window.location.reload();
        }, 100);
      };

      return {
        logout,
      }
    }
  };
</script>

<style lang="scss" scoped>
  .user-info {
    height: 25%;
    width: 100%;
    background-image: url(../assets/images/59dae31a142c0.png);
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    .avatar {
      width: 30%;
      display: flex;
      justify-content: center;
    }
    .extinfo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .account {
        font-size: 36px;
        font-weight: 500;
        line-height: 72px;
      }
      .vips {
        line-height: 48px;
        .item {
          margin-right: 12px;
          padding: 8px;
          font-size: 18px;
          color: #fff;
        }
        >.classic {
          background-color: #D98719;
        }
        >.diamond {
          background-color: #CD7F32;
        }
      }
    }
  }
  .service {
    margin: 0 15px;
    background-color: #fff;
    border-radius: 12px;
    .title {
      font-size: 28px;
      font-weight: 500;
      line-height: 86px;
      margin-left: 18px;
    }
  }
  .logout {
    margin: 20px 15px;

  }
</style>
